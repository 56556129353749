body {
  margin: 0;
}

.calendarElement {
  position: absolute;
  left: 120px;
  transform: translateX(-50%);
  border: 1px solid #ccc;
  z-index: 999;
}

.PrivatePickersYear-yearButton.Mui-selected {
  color: #ffffff;
}

.MuiPickersDay-root.Mui-selected {
  color: #ffffff;
}
